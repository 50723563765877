<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  // Olark chat script
  ;(function(o, l, a, r, k, y) {
    if (o.olark) return;
    r = "script";
    y = l.createElement(r);
    r = l.getElementsByTagName(r)[0];
    y.async = 1;
    y.src = "//" + a;
    y.defer = 1;
    r.parentNode.insertBefore(y, r);
    y = o.olark = function() {
      k.s.push(arguments);
      k.t.push(+new Date);
    };
    y.extend = function(i, j) {
      y("extend", i, j);
    };
    y.identify = function(i) {
      y("identify", k.i = i);
    };
    y.configure = function(i, j) {
      y("configure", i, j);
      k.c[i] = j;
    };
    k = y._ = { s: [], t: [+new Date], c: {}, l: a };
  })(window, document, "static.olark.com/jsclient/loader.js");
  /* Add configuration calls below this comment */
  olark.configure('box.start_hidden', true);
  olark.identify('2077-645-10-7623');
  olark(('api.box.show'));

});
</script>

<template>
  
</template>
